import React from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';

function RouteWithLayout({
  layout,
  component,
  isAuthLoading = false,
  ...rest
}) {
  if (isAuthLoading) {
    return <div>Loading</div>;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        React.createElement(
          layout,
          props,
          React.createElement(component, props)
        )
      }
    />
  );
}
const HomeComponent = React.lazy(() => import('./pages/Home/Home.jsx'));
const NewRequestComponent = React.lazy(() =>
  import('./pages/NewRequest/NewRequest.jsx')
);
const QuestionAnswerUserComponent = React.lazy(() =>
  import('./pages/QuestionAnswerUser/QuestionAnswerUser.jsx')
);
const СategoryComponent = React.lazy(() =>
  import('./pages/Сategory/Сategory.jsx')
);
const NotFoundComponent = React.lazy(() =>
  import('./pages/NotFound/NotFound.jsx')
);
const ServerErrorComponent = React.lazy(() =>
  import('./pages/ServerError/ServerError.jsx')
);
const MainComponent = React.lazy(() => import('./layouts/Main/Main.jsx'));
const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <RouteWithLayout
          exact
          layout={MainComponent}
          component={HomeComponent}
          path="/"
        />

        <RouteWithLayout
          exact
          layout={MainComponent}
          component={NewRequestComponent}
          path={'/new-request'}
        />

        <RouteWithLayout
          exact
          layout={MainComponent}
          component={QuestionAnswerUserComponent}
          path={'/question/:questionId'}
        />

        <RouteWithLayout
          exact
          layout={MainComponent}
          component={СategoryComponent}
          path={'/category/:id'}
        />
        <Route path={'/server-error'} component={ServerErrorComponent} />
        <Route path="*" component={NotFoundComponent} />
      </Switch>
    </React.Fragment>
  );
};
RouteWithLayout.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  isAuthLoading: PropTypes.any
};
export default Routes;
